const LIST_TITLE_REGIONAL = [
  'NORDESTE',
  'NORTE',
  'SUDESTE',
  'SUL',
  'CENTRO - OESTE',
];
const ENEM_UPPER_CASE = 'ENEM';
const ENEM_LOWER_CASE = 'Enem';

const EXAM_CATEGORY_GUIA_DO_VESTIBULADO = 0;
const LIST_ID_EXAMS_GUIA_DO_VESTIBULADO = [1239, 1240];

export {
  LIST_TITLE_REGIONAL,
  ENEM_UPPER_CASE,
  ENEM_LOWER_CASE,
  EXAM_CATEGORY_GUIA_DO_VESTIBULADO,
  LIST_ID_EXAMS_GUIA_DO_VESTIBULADO,
};
