import store from '@/store';

import AppClient from '../AppClient';

export default class ExamsService {
  private basePath = process.env.VUE_APP_EXAMS_API;

  private Client = new AppClient({ url: this.basePath });

  async getExams() {
    const URI = '/exams';

    if (store.getters.examsBook.length) {
      return store.getters.examsBook;
    }

    const response = await this.Client.get(URI);
    store.commit('setExamsBook', response?.data.exams);

    return response?.data.exams;
  }

  async getExam(id: string) {
    const URI = `/exams/${id}`;

    const response = await this.Client.get(URI);

    return response?.data.exams;
  }

  async getExamRelated(id: number | string) {
    const URI = `/exams/file/${id}/related`;

    const response = await this.Client.get(URI);

    return response?.data;
  }
}
