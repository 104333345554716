



























































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ListBooks from '@/components/ListBooks/ListBooks.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Ranking from '@/components/Ranking/index.vue';

import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import ExamsLoading from './ExamsLoading.vue';

import ExamsService from '@/services/Exams/ExamsService';

import permission from '@/mixins/permission';

import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_TYPE_BOOKS } from '@/constant/ListTypesBooks';

import {
  LIST_TITLE_REGIONAL, ENEM_UPPER_CASE, ENEM_LOWER_CASE, EXAM_CATEGORY_GUIA_DO_VESTIBULADO,
} from './constants';

@Component({
  components: {
    PageHeader,
    ListBooks,
    BoxContainer,
    Footer,
    FeedbackUser,
    ExamsLoading,
    RealtimeUsers,
    Ranking,
  },
})
export default class Exams extends Mixins(permission) {
  private isLoadingExams = true;

  private listGuideExam: Array<Record<string, any>> = [];
  private listRegional: Array<Record<string, any>> = [];
  private listNational: Array<Record<string, any>> = [];

  private ExamsService = new ExamsService();

  mounted() {
    this.setBreadCrumbs();
    this.setGuideExams();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  async setGuideExams() {
    try {
      this.isLoadingExams = true;
      const exams = await this.ExamsService.getExams();

      const listGuideExam: Array<Record<string, any>> = [];
      const listRegional: Array<Record<string, any>> = [];
      const listNational: Array<Record<string, any>> = [];

      exams.forEach((exam: Record<string, any>) => {
        const setLink = this.setLinkBook(exam);

        if (exam.category === EXAM_CATEGORY_GUIA_DO_VESTIBULADO) {
          listGuideExam.push({
            ...exam,
            thumb: exam.cover,
            link: setLink,
            type: this.setTypeBookGuia(exam?.title),
            slug: exam?.title,
            disable: false,
            // disable: !this.can(this.setNamePermissionGuia(exam?.title)),
          });
        } else if (LIST_TITLE_REGIONAL.includes(exam.title)) {
          listRegional.push({
            ...exam,
            thumb: exam.cover,
            link: setLink,
            type: 'Regional',
            slug: exam?.title,
            disable: false,
            // disable: !this.can(LIST_PERMISSION.TESTS_REGIONAL),
          });
        } else {
          listNational.push({
            ...exam,
            thumb: exam.cover,
            link: setLink,
            type: this.setTypeBookExam(exam?.title),
            slug: exam?.title,
            disable: false,
            // disable: !this.can(this.setNamePermissionExam(exam?.title)),
          });
        }
      });

      this.listGuideExam = listGuideExam;
      this.listRegional = listRegional;
      this.listNational = listNational;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os vestibulares.',
        status: 'error Loading chunk 6 failed.',
      });
      console.error(error);
    } finally {
      this.isLoadingExams = false;
    }
  }

  setLinkBook(exam: Record<string, any>) {
    return `/vestibular/${exam.title.replace(' ', '')}/${exam.idQuestion}`;
  }

  setNamePermissionGuia(title: string) {
    return title === ENEM_LOWER_CASE ? LIST_PERMISSION.GUIDE_ENEM : LIST_PERMISSION.GUIDE_FUVEST;
  }

  setNamePermissionExam(title: string) {
    return title === ENEM_UPPER_CASE ? LIST_PERMISSION.TESTS_ENEM : LIST_PERMISSION.TESTS_MILITARY;
  }

  setTypeBookGuia(title: string) {
    return title === ENEM_LOWER_CASE ? LIST_TYPE_BOOKS.GUIA_VESTIBULANDO_ENEM : LIST_TYPE_BOOKS.GUIA_VESTIBULANDO_VESTIBULARES;
  }

  setTypeBookExam(title: string) {
    return title === ENEM_UPPER_CASE ? LIST_TYPE_BOOKS.EXAM_NATIONAL_ENEM : LIST_TYPE_BOOKS.EXAM_NATIONAL_MILITARES;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set(
      [
        { title: 'Vestibulares', to: null },
      ],
    );
  }
}
